<template lang="pug">
include ../../../../../configs/template
div.d-flex.row.p-1.w-100
  +field-validation('body.last_name', 'lastName', '["required"]').col-4.w-100.pt-5
  +field-validation('body.first_name', 'name', '["required"]').col-4.w-100.pt-5
  +field('body.userprofile.middle_name', '"middleName"').col-4.pt-5

  div(v-if="checkAccess('backOfficeCrewingManager', 'editGroup')").w-50
    +select-validation('body.userprofile.crewing_company','listCrewingCompanies','crewingCompany','"name_ukr"','["required"]')(item-value="id")
  div(:class="checkAccess('backOfficeCrewingManager', 'editGroup') ? 'w-50' : 'w-100'")
    +select-validation('body.userprofile.branch_office','affiliatesList','affiliate','labelName','["required"]')
  PhoneNumberField(v-model="phone_number" :defaultValue="phone_number" :error-messages="$valid.validation($v.phone_number, ['minLength','maxLength','required'])").mb-6.col-4
  PhoneNumberField(v-model="telegram" :defaultValue="telegram" :error-messages="$valid.validation($v.telegram, ['minLength','maxLength'])", label="Telegram").mb-6.col-4
  PhoneNumberField(v-model="viber" :defaultValue="viber" :error-messages="$valid.validation($v.viber, ['minLength','maxLength'])", label="Viber").mb-6.col-4
  div.d-flex.justify-center.w-100
    v-btn(:loading="buttonLoader"  @click="validationCheck" color="success") {{ $t('save') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { CrewingManagerEdit } from '@/mixins/validationRules'
import { mapActions, mapState } from 'vuex'
import { contacts } from '@/configs/constants'

export default {
  name: 'BackOfficeAgentGroupsInfoEdit',
  components: { PhoneNumberField: () => import('@/components/atoms/PhoneNumberField') },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: {
        last_name: this.sailorDocument.first_name,
        first_name: this.sailorDocument.last_name,
        userprofile: {
          crewing_company: this.sailorDocument.crewing_company.id,
          middle_name: this.sailorDocument.userprofile.middle_name,
          branch_office: this.sailorDocument.userprofile.branch_office
        }
      },
      phone_number: null,
      telegram: null,
      viber: null,
      buttonLoader: false,
      checkAccess
    }
  },
  computed: {
    ...mapState({
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      // mapping documents
      affiliatesList: state => state.directory.affiliate.filter(value => !value.is_disable),
      listCrewingCompanies: state => state.crewing.crewingCompanies.results
    })
  },
  validations () { return CrewingManagerEdit(this) },
  mounted () {
    this.setContactData()
  },
  methods: {
    ...mapActions(['updateUser']),
    /** Set contact models depends on value type */
    setContactData () {
      if (this.sailorDocument.userprofile.contact_info && this.sailorDocument.userprofile.contact_info.length) {
        for (const contact of this.sailorDocument.userprofile.contact_info) {
          Object.entries(contacts).forEach(([key, value]) => { if (contact.type_contact === value.id) this[key] = contact.value })
        }
      }
    },

    /** Check field entries before submit */
    validationCheck () {
      if (this.$v.$invalid) {
        return this.$v.$touch()
      } else this.editAgentInfo()
    },

    /** Edit agent information */
    async editAgentInfo () {
      this.buttonLoader = true
      this.body.userprofile.contact_info = [
        { type_contact: contacts.phone_number.id, value: this.phone_number },
        { type_contact: contacts.telegram.id, value: this.telegram },
        { type_contact: contacts.viber.id, value: this.viber }
      ]

      const response = await this.updateUser({ id: this.sailorDocument.id, body: this.body })
      this.buttonLoader = false
      if ([200, 201].includes(response.code)) {
        this.$notification.success('notify.success.editedCrewingManagerInfo')
        this.$parent.$parent.getData()
      }
    }
  }
}

</script>
